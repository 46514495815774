import {useFormikContext} from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import LeftPoleGateSelfSupporting from './GateSelfSupporting/LeftPole';
import Body from './GateSelfSupporting/Body';
import LeftPoleGateTilting from './GateTilting/LeftPole';
import Middle from './GateTilting/Middle';
import CenterPart from './GateTilting/CenterPart';
import RightPoleGateTilting from './GateTilting/RightPole';
import SingleLeafGateLeftPole from './SingleLeafGate/LeftPole';
import SingleLeafGateMiddle from './SingleLeafGate/Middle';
import SingleLeafGateCenterPart from './SingleLeafGate/CenterPart.jsx';
import SpaceFromOtherProjects from '../SpaceFromOtherProjects';

const RenderContainer = styled.div`
  display: flex;
  width: 100%;
  background: #ffffff;
  justify-content: center;
`;

const Gate = ({ containerHeight }) => {
  const formik = useFormikContext();
  const scale = useSelector((s) => s.app.scale);
  const heightScaled = formik.values.gate.height / scale;

  return (
    <div className="col" style={{ background: '#ffffff' }}>
      <RenderContainer
        id="gateRender"
        style={{height: `${heightScaled}px`}}>
        {formik.values.span.renderDesign && (
        <SpaceFromOtherProjects
          containerHeight={containerHeight}
          type={'span'}
          side={'spaceSideLeft'}
        />
        )}
        {formik.values.gateway.renderDesign && (
        <SpaceFromOtherProjects
          containerHeight={containerHeight}
          type={'gateway'}
          side={'spaceSideLeft'}
        />
        )}
        {(formik.values.gate.renderDesign && formik.values.gate.gateType === 'gateSelfSupporting' && formik.values.gate.width <= 7500)
          && (
          <>
            <LeftPoleGateSelfSupporting />
            <Body
              design={formik.values.gate}
              scale={scale}
              projectType={'gate'}
              fenceType={'gateSelfSupporting'}
            />
          </>
          )}
        {(formik.values.gate.renderDesign && formik.values.gate.gateType === 'gateTilting' && formik.values.gate.width <= 7500) && (
        <>
          <LeftPoleGateTilting />
          <Middle
            design={formik.values.gate}
            scale={scale}
            projectType={'gate'}
            fenceType={'gateTilting'}
          />
          <CenterPart />
          <RightPoleGateTilting />
        </>
        )}
        {(formik.values.gate.renderDesign && formik.values.gate.gateType === 'nonSymmetricalGate') && (
        <>
          <LeftPoleGateTilting />
          <Middle
            design={formik.values.gate}
            scale={scale}
            projectType={'gate'}
            fenceType={'gateTilting'}
          />
          <CenterPart />
          <RightPoleGateTilting />
        </>
        )}
        {(formik.values.gate.renderDesign && (formik.values.gate.gateType === 'singleLeafGate' || formik.values.gate.gateType === 'foldingGate') && formik.values.gate.width <= 6000) && (
        <>
          <SingleLeafGateLeftPole />
          <SingleLeafGateMiddle
            design={formik.values.gate}
            scale={scale}
            projectType={'gate'}
            fenceType={'singleLeafGate'}
          />
          <SingleLeafGateCenterPart />
        </>
        )}
      </RenderContainer>
    </div>
  );
};

export default Gate;
