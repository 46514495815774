import i18next from '../../Translation/config';
import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';


const InfoGateTilting = () => {
    return <div className="platesWidthKey">
      {i18next.language === 'en' && (
      <p>
        The value entered in the "Gate Width" field results from the following conditions:
        <ul>
          <li>Tilting Gate (standard hinges): (Gate Width - 450) / 2 for L = 4999mm</li>
          <li>Tilting Gate (180-degree hinges): ((Gate Width - 30) / 2) - 160 for L = 4999mm</li>
          <li>Tilting Gate (standard hinges): ((Gate Width - 610) /4) from L = 5000mm to L = 5999mm</li>
          <li>Tilting Gate (180-degree hinges): ((Gate Width - 510) / 4) from L = 5000mm to L = 5999mm</li>
          <li>Tilting Gate (standard hinges): ((Gate Width - 640) / 4) from L = 6000mm to L = 7500mm</li>
          <li>Tilting Gate (180-degree hinges): ((Gate Width - 560) / 4) from L = 6000mm to L = 7500mm</li>
        </ul>
        Gate Width - value entered in the "Width of gateway/gate" field
      </p>
      )}
      {i18next.language === 'pl' && (
      <p>
        Wartość podana w polu "Szerokość pola" wynika z poniższych warunków:
        <ul>
          <li>Brama uchylna (zawiasy standardowe): ((Światło - 450) / 2) do L = 4999mm</li>
          <li>Brama uchylna (zawias 180 stopni): (((Światło - 30) / 2) - 160) do L = 4999mm</li>
          <li>Brama uchylna (zawiasy standardowe): ((Światło - 610) /4) od L = 5000mm do L = 5999mm</li>
          <li>Brama uchylna (zawias 180 stopni): ((Światło - 510) / 4) od L = 5000mm do L = 5999mm</li>
          <li>Brama uchylna (zawiasy standardowe): ((Światło - 640) /4) od L = 6000mm do L = 7500mm</li>
          <li>Brama uchylna (zawias 180 stopni): ((Światło - 560) / 4) od L = 6000mm do L = 7500mm</li>
        </ul>
        Światło - wartość wpisana w pole "Szerokość furtki/bramy"
      </p>
      )}
    </div>;
  }
  
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
  export default function InfoGateTiltingPopup() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t } = useTranslation();
  
    return (
      <div>
        <Button 
            onClick={handleOpen}
            style={{ padding: "0.4rem 2rem", background:"#44AF69", color:"white", marginBottom: "10px", borderRadius: "25px" }}
        >{t('popupTitle')}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <InfoGateTilting />
          </Box>
        </Modal>
      </div>
    );
  }
