import React from 'react';
import {useFormikContext} from 'formik';

import {onPropertyChange} from 'Design/Design';
import {InputNumber} from 'components/Input';
import TypeTitle from 'Project/TypeTitle/TypeTitle';

import {InputsContainer} from '../Project';
import CopyValuesBtn from './CopyValuesBtn';
import { useTranslation } from 'react-i18next';

const TopGateway = () => {
  const formik = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      <TypeTitle type="gateway"/>
      {formik.values.gateway.chosen && <InputsContainer>
        <CopyValuesBtn type="gateway"/>
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'gateway', 'width', v)}
            val={formik.values.gateway.widthInit}
            info={formik.touched.gateway?.width && formik.errors.gateway?.width}
            label={t('gatewayWidth')}
            name="gateway.width"
            onBlur={formik.handleBlur}  
          />
          {formik.values.gateway.width > 0 && (
            <b style={{ color: "red" }}>{t('replacedVal')}: {formik.values.gateway.width} mm</b>
          )}
        </div>
        <div className="form-group">
          <InputNumber
            change={(v) => onPropertyChange(formik, 'gateway', 'height', v)}
            val={formik.values.gateway.heightInit}
            info={formik.touched.gateway?.height && formik.errors.gateway?.height}
            label={t('gatewayHeight')}
            name="gateway.height"
            onBlur={formik.handleBlur}
          />
          {formik.values.gateway.height > 0 && (
            <b style={{ color: "red" }}>{t('replacedVal')}: {formik.values.gateway.height} mm</b>
          )}
        </div>
      </InputsContainer>}
    </>
  );
};

export default TopGateway;
