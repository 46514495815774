import styled from 'styled-components';

import {OPTION_P302_WITH_MODULE, SMALL_PLATE_HEIGHT, STANDARD_PLATE_HEIGHT} from 'App';

const calcSupplementSpace = (rodsStructure, width, height, projectType, fenceType = null) => {
  const filledHeight = rodsStructure.reduce((h, struct) => h + struct.size + struct.space, 0);
  const availableHeight = calcAvailableHeight(width, height, projectType, fenceType);

  return availableHeight - filledHeight;
};

const getSize = (projectType, no) => {
  if (projectType === 'gate' || projectType === 'gateway') {
    return no === 0 ? SMALL_PLATE_HEIGHT : STANDARD_PLATE_HEIGHT;
  }
  return STANDARD_PLATE_HEIGHT;
};

const createRodsStructure = (width, height, space, projectType, fenceType = null, moduleHeight = null) => {
  const availableHeight = calcAvailableHeight(width, height, projectType, fenceType);

  const structure = [];
  let tmpHeight = 0;
  let
    no = 0;

  if (moduleHeight) {
    if (tmpHeight <= availableHeight) {
      const size = getSize(projectType, no);
      structure.push({
        size,
        space: 0,
        module: false,
      });
      tmpHeight += size;
      no += 1;
    }

    structure.push({
      size: moduleHeight,
      space: 0,
      module: true,
    });

    tmpHeight += moduleHeight;
  }

  while (tmpHeight <= availableHeight) {
    const size = getSize(projectType, no);
    structure.push({
      size,
      space,
      module: false,
    });

    tmpHeight += size;
    tmpHeight += space;
    no += 1;
  }

  while (tmpHeight > availableHeight) {
    tmpHeight -= STANDARD_PLATE_HEIGHT;
    tmpHeight -= space;
    structure.pop();
  }

  return structure;
};

const calcAvailableHeight = (width, height, projectType, fenceType) => {
  if (projectType === 'gateway') {
    return height - 80 - 80; // frames top and bottom
  }
  if (projectType === 'gate' && (fenceType === 'gateTilting' || fenceType === 'singleLeafGate' || fenceType === 'foldingGate' || fenceType === 'nonSymmetricalGate')) {
    return height - 80 - 80; // frames top and bottom
  }
  if (projectType === 'gate' && fenceType === 'gateSelfSupporting') {
    if (width <= 5800 ) {
      return height - 80 - 80 - 80 - 2; // frames top and bottom
    }
    console.log(width);
    return height - 80 - 80 - 120 - 1; // frames top and bottom
  }

  return height;
};

const canRenderDesign = (option, design) => {
  let width = design.width;
  if (design.leftWingWidth && design.rightWingWidth) {
    width = parseInt(design.leftWingWidth) + parseInt(design.rightWingWidth);
  }
  if (option === OPTION_P302_WITH_MODULE) {
    return design.height > 0
      && width > 0
      && design.space >= 0
      && design.moduleHeight;
  }

  return design.height > 0
    && width > 0
    && design.space >= 0;
};

export const onPropertyChange = (formik, projectType, prop, val) => {
  const v = parseValueRounded(prop, val, projectType)
  const w = prop === 'width' ? v : formik.values[projectType].width;
  const h = prop === 'height' ? v : formik.values[projectType].height;
  const s = prop === 'space' ? v : formik.values[projectType].space;
  const mh = prop === 'moduleHeight' ? v : formik.values[projectType].moduleHeight;
  const gt = prop === 'gateType' ? v : formik.values.gate.gateType;
  const lww = prop === 'leftWingWidth' ? v : formik.values.gate.leftWingWidth;
  const rww = prop === 'rightWingWidth' ? v : formik.values.gate.rightWingWidth;

  const values = {
    ...formik.values[projectType],
    [prop]: v,
  };
  const renderDesign = canRenderDesign(formik.values.option, values);
  const rodsStructure = renderDesign ? createRodsStructure(w, h, s, projectType, gt, mh) : values.rodsStructure;
  const supplementSpace = renderDesign ? calcSupplementSpace(rodsStructure, w, h, projectType, gt) : values.supplementSpace;
  const availableHeight = renderDesign ? calcAvailableHeight(w, h, projectType, gt) : values.availableHeight;

  formik.setFieldValue(projectType, {
    ...values,
    rodsStructure,
    supplementSpace,
    renderDesign,
    availableHeight
  })

  const vNotRounded = parseValue(prop, val);
  if (projectType === 'gate') {
    if (prop === 'height') {
      formik.setFieldValue('gate.heightInit', vNotRounded)
    }
    if (prop === 'width') {
      formik.setFieldValue('gate.widthInit', vNotRounded)
    }
  }
  if (projectType === 'gateway') {
    if (prop === 'height') {
      formik.setFieldValue('gateway.heightInit', vNotRounded)
    }
    if (prop === 'width') {
      formik.setFieldValue('gateway.widthInit', vNotRounded)
    }
  }
}

const parseValueRounded = (prop, val, projectType) => {
  if (projectType !== 'span' && (prop === 'width' || prop === 'height')) {
    const i = parseInt(val);
    return Math.round(i/10) * 10;
  }
  if (
    prop === 'width' || 
    prop === 'height' ||
    prop === 'space' ||
    prop === 'moduleHeight' ||
    prop === 'leftWingWidth' ||
    prop === 'rightWingWidth'
  ) {
    return parseInt(val);
  }
  return val;
}

const parseValue = (prop, val) => {
  if (
    prop === 'width' || 
    prop === 'height' ||
    prop === 'space' ||
    prop === 'moduleHeight' ||
    prop === 'leftWingWidth' ||
    prop === 'rightWingWidth'
  ) {
    return parseInt(val);
  }
  return val;
}

// calculate and set all needed things to render design
// use formik only to set values
export const forceRenderDesign = (formik, values, projectType) => {
  const renderDesign = canRenderDesign(values.option, values[projectType]);
  if (!renderDesign) {
    return;
  }
  const rodsStructure = createRodsStructure(values[projectType].height, values[projectType].space, projectType, values.gate.gateType, values[projectType].moduleHeight);
  const supplementSpace = calcSupplementSpace(rodsStructure, values[projectType].height, projectType, values.gate.gateType);
  const availableHeight = calcAvailableHeight(values[projectType].width, values[projectType].height, projectType, values.gate.gateType);

  formik.setFieldValue(projectType, {
    ...values[projectType],
    rodsStructure,
    supplementSpace,
    renderDesign,
    availableHeight
  })
};
